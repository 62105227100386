<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin phiếu chuyển kho'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-row class="mb-4 ml-4">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch2"
                  v-model="isEdit"
                />
                <label
                  class="custom-control-label text-lable"
                  for="customSwitch2"
                  style="font-size: 14px; font-weight: 500"
                  >Chỉnh sửa</label
                >
              </div>
            </b-row>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-form-group class="col-3">
            <label>Mã phiếu:</label>
            <p>{{ codeStockBill }}</p>
          </b-form-group>
          <b-form-group class="col-3">
            <label>Ngày tạo:</label>
            <p>{{ dateCreate }}</p>
          </b-form-group>
          <b-form-group label-class="font-weight-bolder" class="col-3">
            <label>Người tạo: </label>
            <p>{{ createBy }}</p>
          </b-form-group>
          <b-form-group label-class="font-weight-bolder" class="col-3">
            <label>Phiếu yêu cầu XNK:</label>
            <div
              class="productCode"
              @click="linkToRequireStock()"
              style="cursor: pointer"
            >
              <span v-text="requireStockCode"></span>
            </div>
          </b-form-group>
        </b-row>

        <b-row class="pl-0">
          <b-col md="6">
            <b-row>
              <b-form-group class="col-6 required-control" :disabled="!isEdit">
                <label>Kho nguồn:</label>
                <b-form-select
                  :disabled="true"
                  class="mt-2"
                  v-model="$v.form.sourceStoreSelected.$model"
                  :options="listInventories"
                  size="sm"
                  value-field="id"
                  text-field="name"
                >
                </b-form-select>
              </b-form-group>

              <b-form-group class="col-6 required-control" :disabled="!isEdit">
                <label>Kho đích:</label>
                <b-form-select
                  :disabled="status > 1 ? true : false"
                  class="mt-2"
                  v-model="$v.form.desStoreSelected.$model"
                  :options="listInventories"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >Chọn kho đích</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group :disabled="!isEdit" class="col-12">
                <template>
                  <span>Nội dung:</span>
                </template>
                <b-form-textarea
                  size="sm"
                  v-model="notes"
                  :placeholder="'Thêm nội dung...'"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col md="6">
            <TransferIntermediate
              :items="transferIntermediateItems"
              :editable="false"
            />
          </b-col>
        </b-row>
        <b-row class="mb-4 pl-0">
          <b-col md="2" class="pr-0">
            <b-form-select
              v-model="selectTypeSearch"
              :options="listTypeSearch"
              size="sm"
              value-field="id"
              text-field="name"
              :disabled="status !== 1"
            ></b-form-select>
          </b-col>
          <b-col md="4" class="pl-0">
            <vue-autosuggest
              v-model="searchProduct"
              :suggestions="filteredOptionsProduct"
              @selected="onSelectedProduct"
              :limit="10"
              @input="onInputChangProduct"
              :input-props="inputPropSearchProduct"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading && searchProduct !== ''
              "
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.productName }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
        </b-row>
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          "
        >
          <thead>
            <tr>
              <th scope="col" class="title-center" style="width: 20%">
                Tên sản phẩm
              </th>
              <th scope="col" class="title-center" style="width: 5%">Tồn</th>
              <th scope="col" class="title-center" style="width: 19%">IMEI</th>
              <th scope="col" class="title-center" style="width: 14%">
                Số lượng
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 20%"
                v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
              >
                Đơn giá
              </th>

              <th
                scope="col"
                class="title-center"
                style="width: 15%"
                v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
              >
                Thành tiền
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 10%"
                v-show="status == 1 || status == null"
              ></th>
            </tr>
          </thead>
          <tr v-if="listProductStock.length > 0">
            <td
              :colspan="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 3 : 3"
              class="tdTextAlignPrice"
            >
              Tổng
            </td>
            <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            ></td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            >
              {{ formatMoney(totalPrice) }}
            </td>
            <td></td>
          </tr>
          <tbody v-for="item in listProductStock" :key="item.id">
            <TransferStock
              v-bind:productItem="item"
              v-bind:status="status"
              v-on:cancelAdd="cancelAdd"
              v-on:save="save"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-bind:editState="status === 1"
              v-bind:canViewOriginalPrice="
                checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
              "
            />
          </tbody>
          <tr>
            <td
              :colspan="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 3 : 3"
              class="tdTextAlignPrice"
            >
              Tổng
            </td>
            <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            ></td>
            <td
              class="tdTextAlignPrice"
              v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            >
              {{ formatMoney(totalPrice) }}
            </td>
            <td v-if="status === 1"></td>
          </tr>
        </table>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="updateStock(1)"
          v-if="isEdit"
          >Lưu</b-button
        >
        <b-button
          :style="
            isEdit
              ? 'margin-left: 10px; font-weight: 600; width: 100px'
              : 'font-weight: 600; width: 100px'
          "
          variant="primary"
          size="sm"
          @click="exportExcel"
          >Xuất excel</b-button
        >
        <router-link to="/transfer-stocks" tag="button">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}

.productCode:hover {
  text-decoration: underline;
}
</style>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import TransferStock from '../../components/TransferStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { unMaskPrice } from './../../../utils/common';
import axios from 'axios';
import decounce from 'debounce';
import { BASE_URL, TIME_TRIGGER } from './../../../utils/constants';
import localData from '../../../utils/saveDataToLocal';
import TransferIntermediate from '../../components/transfer-stockslips/TransferIntermediate';

export default {
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      codeStockBill: '',
      dateCreate: '',
      listInventories: [],
      totalPrice: 0,
      listProduct: [],
      listProductSearch: [],
      count: 0,
      notes: '',
      idStock: '',
      form: {
        sourceStoreSelected: null,
        desStoreSelected: null,
        dateImport: '',
      },
      isNew: true,
      createBy: '',
      status: 0,
      listType: [
        { id: 1, name: 'Xuất trả hàng nhà cung cấp' },
        { id: 2, name: 'Xuất kho bán hàng' },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm theo IMEI',
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      totalQuantity: 0,
      requireStockCode: '',
      isSearching: false,
      inputPropSearchProduct: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm sản phẩm',
        disabled: false,
      },
      isEdit: false,
      transferIntermediateItems: [],
    };
  },
  validations: {
    form: {
      sourceStoreSelected: { required },
      desStoreSelected: { required },
      dateImport: { required },
    },
  },
  components: {
    KTCodePreview,
    TransferStock,
    TransferIntermediate,
  },
  created() {
    this.fetchStore();
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển kho', route: '/transfer-stocks' },
      { title: 'Cập nhật phiếu chuyển kho' },
    ]);
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      let imeiCode = '';
      let quantity = 0;
      if (this.selectTypeSearch === 2) {
        imeiCode = option.item.imeiCode;
        quantity = 1;
        if (!this.isExistImeiCode(imeiCode, this.listProductStock)) {
          this.count++;
          let pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            totalPrice: this.selectedClassProduct.originalPrice * quantity,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
          this.checkTotalMoney();
        } else {
          this.makeToastFaile('Mã IMEI đã được thêm vào danh sách!');
        }
      } else {
        if (this.selectedClassProduct.productType === 1) {
          quantity = 1;
          let index = this.listProductStock.findIndex(
            (item) => item.proId === this.selectedClassProduct.id
          );
          if (index === -1) {
            let pro = {
              id: this.count,
              name: this.selectedClassProduct.productName,
              productCode: this.selectedClassProduct.productCode,
              barCode: this.selectedClassProduct.barCode,
              totalQuantityInStock:
                this.selectedClassProduct.totalQuantityInStock != null
                  ? this.selectedClassProduct.totalQuantityInStock
                  : 0,
              IMEI: imeiCode,
              proId: this.selectedClassProduct.id,
              price: this.selectedClassProduct.originalPrice,
              quantity: quantity,
              totalPrice: this.selectedClassProduct.originalPrice * quantity,
              productType: this.selectedClassProduct.productType,
            };
            this.listProductStock.unshift(pro);
          } else {
            this.listProductStock[index].quantity =
              this.listProductStock[index].quantity + quantity;
          }
          this.checkTotalMoney();
        } else if (this.selectedClassProduct.productType === 2) {
          let pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            totalPrice: this.selectedClassProduct.originalPrice * quantity,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
        }
      }
      this.searchProduct = '';
      this.checkTotalMoney();
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.listProductSearch = [];
      ApiService.setHeader();
      ApiService.get(
        `stocks/get-product-by-store?storeId=${this.$v.form.sourceStoreSelected.$model}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          const nextSearch = this.searchProduct;
          this.fetchProduct(nextSearch);
        }
      });
    },
    updateStock: async function (status) {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const listPro = this.listProductStock.map((element) => {
        return {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
        };
      });
      const data = {
        code: this.codeStockBill,
        id: this.idStock,
        sourceStoreId: this.$v.form.sourceStoreSelected.$model,
        desStoreId: this.$v.form.desStoreSelected.$model,
        description: this.notes,
        transferDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD'
            )
          : '',
        status: status,
        listProduct: listPro,
      };
      ApiService.post('stocks/update-transfer-stock', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listProduct = [];
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                path: '/transfer-stocks',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    updateStockComplete: async function () {
      ApiService.setHeader();
      ApiService.post(`stocks/complete-transfer-stock/${this.idStock}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                path: '/transfer-stocks',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    addRow() {
      this.count++;
      let data = {
        id: this.count,
        name: '',
        IMEI: '',
        proId: null,
        price: 0,
        quantity: 0,
        totalPrice: 0,
      };
      if (this.listProduct.length == 0) {
        this.listProduct.unshift(data);
      } else if (this.listProduct[0].name != '') {
        this.listProduct.unshift(data);
      }
    },
    cancelAdd() {
      this.listProduct.shift();
    },
    save(item) {
      this.listProduct[0] = item;
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    checkTotalMoney() {
      this.totalPrice = 0;
      this.totalQuantity = 0;
      this.listProductStock.forEach((element) => {
        let total = element.quantity * unMaskPrice(element.price);
        this.totalPrice += total;
        this.totalQuantity += parseInt(element.quantity);
      });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = item.price;
          this.listProductStock[index].quantity = parseInt(item.quantity);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    getListDetailStock() {
      ApiService.setHeader();
      ApiService.get(`stocks/list-stock-detail/${this.idStock}`).then(
        ({ data }) => {
          data.data.forEach((element) => {
            let dataItem = {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
            };
            this.listProduct.push(dataItem);
          });
          this.checkTotalMoney();
        }
      );
    },
    getStockById() {
      const id = this.$route.query.id;
      ApiService.get(`stocks/transfer-stock/${id}`).then(({ data }) => {
        let dataRes = data.data;
        this.codeStockBill = dataRes.code;
        this.dateCreate = moment(String(dataRes.createdAt)).format(
          'HH:mm DD/MM/YYYY'
        );
        this.$v.form.dateImport.$model = moment(
          String(dataRes.transferDate)
        ).format('DD-MM-YYYY');
        this.$v.form.sourceStoreSelected.$model = dataRes.sourceStoreId;
        this.$v.form.desStoreSelected.$model = dataRes.desStoreId;
        this.totalPrice = dataRes.totalAmount;
        this.notes = dataRes.description;
        this.createBy = dataRes.createdBy;
        this.status = dataRes.status;
        this.idStock = dataRes.id;
        this.requireStockCode = dataRes.relateStockCode;

        dataRes.listDetail.forEach((element) => {
          this.count++;
          let dataItem = {
            id: element.id,
            name: element.productName,
            productCode: element.productCode,
            barCode: element.barCode,
            IMEI: element.productImei.replace(', ', '\n'),
            proId: element.productId,
            price: element.unitPrice,
            quantity: element.quantity,
            totalPrice: element.totalAmount,
            productType: element.productType,
            totalQuantityInStock:
              element.totalQuantityInStock != null
                ? element.totalQuantityInStock
                : 0,
          };
          this.listProductStock.push(dataItem);
        });
        this.transferIntermediateItems = dataRes.transferIntermediates.map(
          (transferIntermediate, index) => {
            return {
              count: index + 1,
              ...transferIntermediate,
            };
          }
        );
        this.inputPropSearchProduct.disabled =
          dataRes.status === 1 ? false : true;
        this.checkTotalMoney();
      });
    },
    fetchProductImei(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      ApiService.setHeader();
      ApiService.get(
        `stocks/get-product-by-store-imei?storeId=${this.$v.form.sourceStoreSelected.$model}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        if (products.length === 1) {
          this.selectedClassProduct = products[0];
          this.count++;
          let pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: this.selectedClassProduct.imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: 1,
            totalPrice: this.selectedClassProduct.originalPrice,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
          this.checkTotalMoney();
          this.searchProduct = '';
          this.isSearching = false;
        } else {
          products.map((element) => {
            this.optionsProduct[0].data.push(element);
          });
          this.filteredOptionsProduct = [
            {
              data: this.optionsProduct[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProduct) {
            const nextSearch = this.searchProduct;
            this.fetchProductImei(nextSearch);
          }
        }
      });
    },
    exportExcel: async function () {
      ApiService.setHeader();
      let baseUrl = BASE_URL;
      //let baseUrl = 'http://localhost:3000/api/';
      let url = `${baseUrl}stocks/export-excel/${this.idStock}?type=3`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        // console.log(`Response: ${JSON.stringify(response)}`);
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    linkToRequireStock: function () {
      this.$router.push({
        name: 'list-require-stock',
        query: { code: this.requireStockCode },
      });
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei(textSearch);
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
